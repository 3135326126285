import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import QuickView from "../pages/Product/QuickView";
const ProductCard1 = (props) => {
  // const [modalShow, setModalShow] = React.useState(false);
  const [pID, setPID] = useState();
  const [whatsappUrl, setWhatsappUrl] = useState(
    `https://wa.me/?text=Hello, See these amazing products. ${window.location.href}`
  );

  const [defaultInfo, setDefaultInfo] = useState({});
  const [product, setProduct] = useState({});
  const [image, setImage] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    // console.log();
    setProduct(props.item);
    console.log('props', props.item);

    // setDefaultInfo()
    setDefaultInfo(
      props.item.Product_varients.find((variant) => variant.set_default)
    );
    console.log("default",
      props.item.Product_varients.find((variant) => variant.set_default)
    );

    // console.log(typeof props.item.image);
    setImage(props.item.image[0]);
    // console.log(props.item);
  }, []);

  const addToCart = (product) => {
    let tmpID = localStorage.getItem("tmpID");
    let obj = {
      productId: product._id,
      quantity: 1,
      tmpUserID: tmpID,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}addToCart`, obj)
      .then(function (response) {
        toast.success(response.data.message);
        // console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //animation
  let rotation = 0;
  let timing = 150;
  const [isBooped, setIsBooped] = React.useState(false);
  const style = {
    display: "inline-block",
    backfaceVisibility: "hidden",
    transform: isBooped ? `rotate(${rotation}deg)` : `rotate(0deg)`,
    transition: `transform ${timing}ms`,
  };
  React.useEffect(() => {
    if (!isBooped) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);
  const trigger = () => {
    setIsBooped(true);
  };

  // const ModalShow = (id) => {
  //   setModalShow(true);
  //   setPID(pID);
  // };
  return (
    <>
      <div className="col-xl-3 col-md-3 col-6 cardd">
        <div
          className={`ribbon   ${
            Math.floor(((product?.mrp - product.price) * 100) / product.mrp) > 0
              ? `d-block`
              : `d-none`
          }`}
        >
          <span
            className={` paraS ${
              Math.floor(((product?.mrp - product.price) * 100) / product.mrp) >
              0
                ? `d-block`
                : `d-none`
            }`}
          >
            {defaultInfo.mrp ? (
              `${
                Math.floor(
                  ((defaultInfo.mrp - defaultInfo.price) * 100) /
                    defaultInfo.mrp
                ) > 0
                  ? Math.floor(
                      ((defaultInfo.mrp - defaultInfo.price) * 100) /
                        defaultInfo.mrp
                    )
                  : ''
              }`
            ) : (
              <>
                {Math.floor(
                  ((product.mrp - product.price) * 100) / product.mrp
                ) > 0
                  ? Math.floor(
                      ((product.mrp - product.price) * 100) / product.mrp
                    )
                  : ''}
              </>
            )}
            % offer
          </span>
        </div>
        <div className="shadow">
          <div className="position-relative overflow-hidden">
            <Link to={`/product-detail/${product.slug}`}>
              <img
                src={image}
                className="img-fluid object-fit-cover img-hover"
                style={{
                  width: '100%',
                  aspectRatio: '4/4',
                  objectPosition: 'top',
                }}
                alt="img"
                loading="lazy"
              />
            </Link>
            {/* <div
              className="text-center bg-dark text-white  position-absolute w-100 view"
              onClick={() => ModalShow(product._id)}
            >
              <span className="fs-6 fw-semibold">QUICK VIEW</span>
            </div> */}
            <Link
              to={whatsappUrl}
              style={{ textDecoration: 'none', color: '#000' }}
            >
              {' '}
              <div className="text-center bg-body-secondary rounded-circle position-absolute favrate px-2 py-1">
                <span className="fs-6 fw-semibold">
                  {' '}
                  <i class="fa fa-share-alt  titleS" aria-hidden="true"></i>
                </span>
              </div>
            </Link>
          </div>
          <Link
            to={`/product-detail/${product.slug}`}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <div className="d-flex flex-column text-center align-items-center justify-content-center p-3">
              <div className="mb-2 text-secondary fw-semibold paraS">
                {product.name}
              </div>
              <div>
                {/* <del className="paraS"><i class="fa fa-inr" aria-hidden="true" ></i>{product.mrp}</del> */}
                {defaultInfo?.mrp ? (
                  <>
                    <del className="text-secondary me-2">
                      ₹{defaultInfo.mrp}
                    </del>
                  </>
                ) : (
                  <>
                    <del className="text-secondary me-2">₹{product.mrp}</del>
                  </>
                )}
                &nbsp;&nbsp;
                {defaultInfo ? (
                  <span className="fw-semibold  paraS">
                    <i class="fa fa-inr" aria-hidden="true"></i>
                    {defaultInfo.price}
                  </span>
                ) : (
                  <span className="fw-semibold paraS">
                    <i
                      class="fa fa-inr"
                      aria-hidden="true"
                      style={{ fontSize: '1.3em' }}
                    ></i>
                    {product.price}
                  </span>
                )}
              </div>
            </div>
          </Link>
        </div>
      </div>
      {/* <QuickView
        show={modalShow}
        productId={pID}
        productSlug={product.slug}
        onHide={() => setModalShow(false)}
      /> */}
    </>
  );
};
export default memo(ProductCard1);
