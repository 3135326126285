import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function Newarrivals() {

    const [newarrivals , setNewArrivals]=useState([]);
    const BASE_URL=process.env.REACT_APP_BASE_URL;
    const getNewArrivals = () => {
        axios
            .get(`${BASE_URL}NewArrivals`,
                {
                    "Content-Type": "Application/json",
                    "version": "1.0.0"
                }).then((response) => {
                    if (response.data.success == false) {

                    } else {
                        setNewArrivals(response.data.data);
                        console.log("New arriavals", response.data.data);
                    }
                })
    }

useEffect(()=>{
    getNewArrivals();
},[])

    return (
        <div className='container newarrivalCont mt-5'>
            <div className='text-center d-flex justify-content-center'>
                <div >
                <div className='p-5 w-100'>
                <h1 className="text-center text-uppercase" ><span className='' style={{borderBottom:"3px solid yellow",fontSize:"40px"}}>New Arrivals</span></h1>
                {/* <div className='d-flex justify-content-content'>
                <div className='' style={{width:"100%",height:"3px",backgroundColor:"yellow"}}></div>
                </div> */}
                </div >
                <div className='p-5' style={{
fontSize:"20px",
color:"#637E76"
}}>Basel & Co. is a powerful eCommerce theme for Shopify. Visit our shop page to see all main features for Your Store</div>

                </div>
               
            </div>
            <div className="row newarrv">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                    {
                        newarrivals.length>0?<> <img src={newarrivals[0].image[0]} className='w-100 h-100 p-2' style={{objectFit:"cover"}}/>
                        {/* <button className='newArrivalbtn1 ' style={{padding:"10px",backgroundColor:"#fff",color:"#637e76"}}>{newarrivals[0].name}</button>  */}
                        </>:""
                    }
                   
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 ">
                    <div className="row" >
                        <div className="col-12">
                            {
                                newarrivals.length>0&&newarrivals[1]?<>
                                 <img src={newarrivals[1].image[0]} className='w-100 h-100 p-2' />
                            {/* <button className='newArrivalbtn2 ' style={{padding:"10px",backgroundColor:"#fff",color:"#637e76"}}>{newarrivals[1].name}</button> */}
                                </>:""
                            }
                           
                        </div>
                        <div className=" col-12">
                            {
                                newarrivals.length&&newarrivals[2]?<>
                                   <img src={newarrivals[2].image[0]} className='w-100 h-100 p-2'  />
                            {/* <button className='newArrivalbtn3 ' style={{padding:"10px",backgroundColor:"#fff",color:"#637e76"}}>{newarrivals[2].name}</button> */}
                                </>:""
                            }
                         
                        </div>
                    </div>
                </div>
                <div className="thirdcol col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    {
                        newarrivals.length>0&&newarrivals[3]?<>
                        <img src={newarrivals[3].image[0]} className="thirdcolimg w-100 h-100 p-2 pt-3"  />
                    {/* <button className='newArrivalbtn4 ' style={{padding:"10px",backgroundColor:"#fff",color:"#637e76"}}>{newarrivals[3].name}</button> */}
                        </>:""
                    }
                    
                </div>
            </div>
        </div>
    )
}
