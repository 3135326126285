import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import OwlCarousel from 'react-owl-carousel';
// import './Owlcarousel.css';


const Testimonial = () => {
    const [testimonials , setTestimonial]=useState([]);
    const options = {
        items: 1, // Set the number of items to 1
        loop: true,
        margin: 10,
        nav: true,
        navText: [
            '<span class="prev-arrow">❮</span>',
            '<span class="next-arrow">❯</span>',
        ],
    };

    let getAllTestimonial = () => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}getAllTestimonial`)
          .then(function (response) {
            // handle success
            // if (mounted) {
            if (response.data.success) {
              setTestimonial(response.data.data);
              console.log("setTestimonial",response.data.data);
            }
            // }
    
            // bindCategory();
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      };

useEffect(()=>{
    getAllTestimonial();
},[])

 
    return (
        <>
            <div style={{
                            backgroundImage: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.6)),url(https://img.freepik.com/premium-photo/teddy-bear-with-rainbow-pastel-toy-blank-wood-frame-white-deskai-generated-image_377307-4667.jpg?w=996)`, backgroundRepeat: "no-repeat", backgroundPosition: "right", backgroundSize: "cover", height: "370px", backgroundAttachment: "fixed"
                        }} >
                            <Carousel
                                autoPlay
                                showThumbs={false}
                                className=''
                                style={{width:"100%"}}

                            >
                                {
                                    testimonials.length ? testimonials.map((item, index) => (
                                        <>
                                            <div style={{ height: "500px!important",width:"100%" }} key={index}>
                                                {/* <div className='row w-100'> */}
                                                 
                                                    {/* <div className='col-8'> */}
                                                        <div className='d-flex justify-content-start align-items-center' style={{ minHeight: "310px" }}>
                                                            <div className='ms-md-5 me-md-5' style={{ textAlign: "start" }} >
                                                                <span><img src={item.image} className='img-fluid' style={{ borderRadius: "50%", width: "50px", height: "50px" }} /></span> <span className='mb-3' style={{
                                                                    fontSize: "2.5rem", paddingTop: "55px", color: "#000", overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    color:"#fff"
                                                                }}>{item.name}</span>
                                                                <br />
                                                                <span style={{
                                                                    position: "relative",
                                                                    top: "-12px",
                                                                    left: "59px"
                                                                }}>

                                                                    {Array.from({ length: item.rate }, (_, i) => i + 1).map((rate) => (
                                                                        <>
                                                                            <span className='fa fa-star' style={{ color: 'yellow' }}></span>

                                                                        </>
                                                                    ))}

                                                                </span>

                                                                <p className='fs-4' style={{ color: "#fff" }}>{item.description}   </p>
                                                            </div>

                                                        </div>
                                                    {/* </div> */}
                                                    {/* <div className='col-0 col-md-2'>

                                                    </div> */}
                                                {/* </div> */}

                                            </div>
                                        </>
                                    )) : ""
                                }

                                {/* <div style={{ height: "500px!important" }}>
                    <div className=''>
                        <p className='mb-3' style={{ fontSize: "4rem" }}>jdsfsdjfj</p>
                        <p>jdsffffffffffffffffffff </p>
                    </div>
                </div>  */}
                                {/* <div style={{ height: "500px!important" }}>
                    <div className='' style={{minHeight:"200px"}}>
                        <p className='mb-3' style={{ fontSize: "4rem" }}>jdsfsdjfj</p>
                        <p>jdsffffffffffffffffffff </p>
                    </div>
                </div> */}
                            </Carousel>

                        </div>
        </>
    );
};
export default Testimonial;
