import React, { createContext, memo, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import RecordNotFound from "../Components/RecordNotFound";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ProductBreadcrumb from "../Components/productBreadcrumb";
import Home from "./Home";
import {CartContext} from "../App"
import './cart.css'
// import { CartContext } from '../App';

import TopBar from "../Components/TopBar";
import Navmenu from "../Components/Navmenu";
import Footer from "../Components/Footer";


const Layout = React.lazy(() => import('../Components/Layout'));
// const PageBreadcrumb = React.lazy(() => import('../Components/Breadcrumb'));
// import {cartItm} from "../App"

// const cartL = createContext();


const ProductCart = () => {
  const navigate = useNavigate();

  // const cartitem=useContext(cartItm);
  const [cartItem, setCartItem] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const state = useContext(CartContext);
  // const [cartItem, setCartItem] = useState([]);
  const [cartlen, setCartlen] = useState();
  const [quantity, setQuantity] = useState(1);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [user, setUser] = useState({});

  const loadCartList = () => {
    let tmpID = localStorage.getItem("tmpID");

    const obj = {
      tmpUserID: tmpID,
    };
    axios
      .post(`${BASE_URL}getAllCart`, obj,
        {
          headers: {
            "version": "1.0.0",
            "Content-type": "Application/json"
          }
        })
      .then(function (response) {
        setCartItem(response.data.data);
        console.log(response.data.data);

        setCartlen(response.data.data.length);

        let valueAdded = 0;

        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].price !== undefined) {
            valueAdded +=
              response.data.data[i].price * response.data.data[i].quantity;
          } else if (
            response.data.data[i].Product_details &&
            response.data.data[i].Product_details[0].price !== undefined
          ) {
            valueAdded +=
              response.data.data[i].Product_details[0].price *
              response.data.data[i].quantity;
          }
        }
        if (valueAdded != 0) {
          setTotalAmount(valueAdded);
          console.log('valueAdded', valueAdded);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateQnt = (type, cartID) => {
    if (type === "-") {
      setQuantity(quantity - 1);
    } else {
      setQuantity(quantity + 1);
    }
    let obj = {
      quantity: quantity,
    };
    axios
      .put(`${process.env.REACT_APP_BASE_URL}updateCart/${cartID}`, obj)
      .then(function (response) {
        console.log(response);
        loadCartList();
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log("cartID ==>", cartID);
    console.log("quantity ==>", quantity);
  };

  const deletCartItem = (cartID) => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}deleteCart/${cartID}`)
      .then(function (response) {
        console.log(response);
        toast.info(response.data.message);
        loadCartList();
        // state?.loadCartList();
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log("cartID ==>", cartID);
    console.log("quantity ==>", quantity);
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user != null) {
      setUser(user);
    }
    loadCartList();
  }, []);





  return (
    <>
      {/* <cartL.Provider value={cartlen}> */}

      {/* <Layout> */}
      <header class="header">
        <TopBar />
        <Navmenu />
      </header>
      <ProductBreadcrumb
        home={
          <i className="fa fa-home" style={{ fontSize: '25px' }}>
            {' '}
            &nbsp;
            <span
              className="	fa fa-angle-right"
              style={{ fontSize: '25px' }}
            ></span>
            &nbsp;
          </i>
        }
        slugObj1="Cart"
      />

      {cartItem && cartItem.length > 0 ? (
        <>
          <div class="product_detail_content">
            <div class="product_detail_main">
              <div className="basket">
                {/* <label htmlFor="promo-code">Enter a promotional code</label> */}
                {/* <div className="basket-module">
              <input
                id="promo-code"
                type="text"
                name="promo-code"
                maxLength={5}
                className="promo-code-field"
              />
              <button className="promo-code-cta">Apply</button>
            </div> */}
                <div className="basket-labels">
                  <ul>
                    <li className="cart-Listt-heading item item-heading fs-4">
                      Item
                    </li>
                    <li className=" cart-Listt-heading quantity fs-4">
                      Quantity
                    </li>
                    <li className="cart-Listt-heading price fs-4">Price</li>

                    <li className="cart-Listt-heading subtotal fs-4">Action</li>
                  </ul>
                </div>

                {cartItem.length > 0 &&
                  cartItem.map((item, index) => (
                    <>
                      <div className="basket-product" index={index}>
                        <div className="item">
                          <div className="product-image">
                            <img
                              src={item.Product_details[0]?.image[0]}
                              alt={item.Product_details[0]?.name}
                              className="product-frame"
                            />
                          </div>
                          <div className="product-details">
                            <h2>
                              <strong>{item.Product_details[0]?.name}</strong>{' '}
                            </h2>
                            <p className="fs-4">
                              <strong>Size:{item.size}</strong>
                            </p>
                            <p className="fs-4">
                              Product Code - {item.productId}
                            </p>
                          </div>
                        </div>
                        <div className="quantity">
                          {/* <input
                        type="number"
                        defaultValue={4}
                        min={1}
                        className="quantity-field"
                      /> */}

                          <h5 className="text-grey mt-1 mr-1 ml-1fs-4">
                            <i
                              className="fa fa-minus text-danger fs-4"
                              style={{ marginRight: 5 }}
                              onClick={() =>
                                item.quantity === 1
                                  ? alert(
                                      'The quantity shall not be less than one.'
                                    )
                                  : updateQnt('-', item._id, item)
                              }
                            />
                            &nbsp;&nbsp;
                            {item.quantity}
                            &nbsp;&nbsp;
                            <i
                              className="fa fa-plus text-success fs-4"
                              style={{ marginLeft: 5 }}
                              onClick={() => updateQnt('+', item._id, item)}
                            />
                          </h5>
                        </div>
                        <div className="price fs-4">
                          {' '}
                          {item.price
                            ? item.price * item.quantity
                            : item.Product_details[0]?.price * item.quantity}
                        </div>

                        <div className="subtotal fs-4 d-flex justify-content-end">
                          <button
                            className="btn buttonBlue"
                            onClick={() =>
                              navigate('/cart-info', {
                                state: item.frameInfoId,
                              })
                            }
                          >
                            <i className="fa fa-eye fs-4" ></i>
                          </button>
                        </div>
                        <div className="remove">
                          <button
                            onClick={() => deletCartItem(item._id)}
                            className="fs-4"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
            <div class="product_detail_side">
              {' '}
              <aside>
                <div className="summary">
                  <div className="summary-total-items">
                    <span className="total-items fs-4"> Items in your Bag</span>
                  </div>

                  {cartItem.length > 0 &&
                    cartItem.map((item, index) => (
                      <>
                        <div className="row p-3">
                          <div className="col fs-4 text-capitalize">
                            {item.Product_details[0].name}
                          </div>
                          <div className="col text-end fs-4">
                            {item.price ? (
                              <>
                                {item.quantity} x {item.price} =
                                {item.quantity * item.price}
                              </>
                            ) : (
                              <>
                                {item.quantity} X{' '}
                                {item.Product_details[0]?.price} ={' '}
                                {item.quantity * item.Product_details[0]?.price}
                              </>
                            )}
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                  {/* <div className="summary-delivery">
                <select
                  name="delivery-collection"
                  className="summary-delivery-selection"
                >
                  <option value={0} selected="selected">
                    Select Collection or Delivery
                  </option>
                  <option value="collection">Collection</option>
                  <option value="first-class">Royal Mail 1st Class</option>
                  <option value="second-class">Royal Mail 2nd Class</option>
                  <option value="signed-for">
                    Royal Mail Special Delivery
                  </option>
                </select>
              </div> */}
                  <div className="summary-total">
                    <div className="total-title fs-4">Total</div>
                    <div className="total-value final-value fs-4" id="basket-total">
                      {totalAmount}
                    </div>
                  </div>
                  <div className="summary-checkout">
                    <button
                      className="checkout-cta fs-4"
                      onClick={() =>
                        navigate(
                          user
                            ? '/checkout'
                            : '<script>alert("please login first")</script>'
                        )
                      }
                    >
                      Go to Secure Checkout
                    </button>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="h-100 d-flex justify-content-center align-items-center">
            <div>
              <img
                src="https://img.freepik.com/free-photo/3d-man-with-shopping-cart-blank-screen-tablet_1401-15.jpg?size=626&ext=jpg&ga=GA1.1.1715450231.1708072499&semt=ais"
                style={{ width: '300px' }}
              />
              <h3 className="text-center">Cart Is Empty</h3>
            </div>
          </div>
        </>
      )}

      {/* <Footer /> */}
      {/* </Layout> */}
    </>
  );
}
export default ProductCart;