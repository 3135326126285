import React, { memo, useContext, useEffect, useState } from "react";
// import Footer from "../../Components/Footer";
// import TopBar from "../../Components/TopBar";
// import Header from "../../Components/Header";
// import Spinner from 'react-bootstrap/Spinner';

// import ReactImageMagnify from 'react-image-magnify';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { InlineShareButtons } from "sharethis-reactjs";
import { Accordion, Container, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Image from "react-bootstrap/Image";
import { CartContext } from "../../App";
import ProductBreadcrumb from "../../Components/productBreadcrumb";
// import RelatedProduct from "./RelatedProduct";
import TopBar from "../../Components/TopBar";
import Navmenu from "../../Components/Navmenu";
import Footer from "../../Components/Footer";
import Form from 'react-bootstrap/Form';

const Layout = React.lazy(() => import("../../Components/Layout"));

const initialValues = {
  baby_name: '',
  baby_dob: '',
  baby_tob: '',
  baby_weight: '',
  hospital_name: '',
  baby_gender: '',
  blood_group: '',
  mother_name: '',
  father_name: '',
  // baby_image:''
};
const ProductDetails = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [baby_image, setBabyImage] = useState('');
  const [mother_image, setMotherImage] = useState('');
  const [father_image, setFatherImage] = useState('');
  const [parents_image, setParentsImage] = useState('');
  // const [baby_gender, setBabyGender] = useState('');
  const [values, setValues] = useState(initialValues);
  const state = useContext(CartContext);
  const [pimg, setPimg] = useState('');
  const [defaultInfo, setDefaultInfo] = useState([]);
  const [productvarient, setProductvarient] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id);
  const [loading, setLoding] = useState(false);
  const [product, setProduct] = useState({});
  const [productimg, setProductImg] = useState([]);
  const [imgsource, setImgSource] = useState('');
  const [price, setPrice] = useState();
  const [color, setColor] = useState('');
  const [user, setUser] = useState();
  const [whatsappUrl, setWhatsappUrl] = useState(
    `https://wa.me/?text=Hello, See these amazing products. ${window.location.href}`
  );
  useEffect(() => {
    let user = localStorage.getItem('user');
    if (user != null) {
      setUser(user);
    }
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const productView = async () => {
    await axios
      .get(`${BASE_URL}ProductView/${id}`, {
        headers: {
          'Content-Type': 'Application/json',
        },
      })
      .then((response) => {
        if (response.data.data.success == false) {
        } else {
          // varients(response.data.data._id);
          setPimg(response.data.data[0].image[0]);
          setPrice(response.data.data[0].price);

          setProductImg(response.data.data[0].image);
          setProduct(response.data.data[0]);
          let filtereddata = response.data.data[0].Product_varients.filter(
            (variant) => variant.set_default == true
          );
          setDefaultInfo(filtereddata);
          // setColor(filtereddata[0].color_[0].color_code);
          console.log('product default', filtereddata[0].price);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    productView();
  }, [id]);
  const addToCart = (product, id) => {
    console.log('clicked');
    let tmpID = localStorage.getItem('tmpID');
    setLoding(true);
    console.log('product ==>', product);
    let obj = {
      productId: product._id,
      quantity: 1,
      tmpUserID: tmpID,
      price: defaultInfo[0] ? defaultInfo[0].price : product.price,
      size: defaultInfo[0]?.size,
      frameInfoId: id
      // color: color,
    };

    // console.log("price", price);
    // console.log("size",defaultInfo.size);
    // return;
    axios
      .post(`${BASE_URL}addToCart`, obj)
      .then(function (response) {
        if (response.data.success == true) {
          // toast.success('Product added to cart successfully');
          // state?.loadCartList();
          setLoding(false);
          navigate('/cart');
          // if (type == 2) {
          //   navigate('/cart');
          // }
        } else {
          toast.error('You have already added this product in your cart');
          setLoding(false);
        }
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
      });
  };
  const changeImg = (item) => {
    setImgSource(item);
  };
  const renderHTML = (rawHTML) =>
    React.createElement('div', {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  const HandleClick = (item) => {
    setPimg(item);
  };

  const setGetDefault = async (item) => {
    setDefaultInfo([item]);
    setPrice(item.price);
    // setColor(item.color_[0].color_code);
  };
  // const changeColor = (item) => {
  //   setColor(item);
  // };

  const handleComplete = (product) => {
    let tmpID = localStorage.getItem('tmpID');

    //  console.log('price', defaultInfo[0].price);
    // console.log('size', defaultInfo[0].size);
    // return;
    // console.log("product", product);
    // alert('You r done. TQ');
    //   console.log("click")
    if (!values.baby_name) {
      toast.error('Baby Name is required!');
      return;
    }
    if (!values.baby_dob) {
      toast.error('Baby Date of Birth is required!');
      return;
    }
    if (!values.baby_tob) {
      toast.error('Baby Time of Birth is required!');
      return;
    }
    if (!values.baby_weight) {
      toast.error('Baby Weight is required!');
      return;
    }
    if (!values.hospital_name) {
      toast.error(' Hospital Name Or The Place Where Baby Birth Is Required!');
      return;
    }
    if (!baby_image[0]) {
      toast.error('Please Provide Baby Image');
      return;
    }
    if (!values.baby_gender) {
      toast.error(' Baby Gender  Is Required!');
      return;
    }
    if (!values.mother_name) {
      toast.error(' Baby Mother Name  Is Required!');
      return;
    }
    if (!values.father_name) {
      toast.error(' Baby Father Name  Is Required!');
      return;
    }

    if (!mother_image[0]) {
      toast.error(' Please Provide Baby Mother Image!');
      return;
    }
    if (!father_image[0]) {
      toast.error(' Please Provide Baby Father Image!');
      return;
    }
    if (!parents_image[0]) {
      toast.error(' Please Provide Prents Image!');
      return;
    }

    const fd = new FormData();
    fd.append('tempId', tmpID);
    fd.append('baby_name', values.baby_name);
    fd.append('baby_dob', values.baby_dob);
    fd.append('baby_tob', values.baby_tob);
    fd.append('baby_weight', values.baby_weight);
    fd.append('hospital_name', values.hospital_name);
    fd.append('baby_gender', values.baby_gender);
    fd.append('blood_group', values.blood_group);
    fd.append('mother_name', values.mother_name);
    fd.append('father_name', values.father_name);
    fd.append('baby_image', baby_image[0]);
    fd.append('mother_image', mother_image[0]);
    fd.append('father_image', father_image[0]);
    fd.append('parents_image', parents_image[0]);
    fd.append('product_id', product._id);

    axios
      .post(`${BASE_URL}addframedata`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
          version: '1.0.0',
        },
      })
      .then((res) => {
        if (res.data.success) {
          // console.log()
          addToCart(product, res.data.data._id)
          toast.success('information added successfully');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onInputChanged = (event) => {
    // fd.append()
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setValues((values) => ({
      ...values,
      [targetName]: targetValue,
    }));
  };
  return (
    <>
      <div class="">
        <header class="product_detail_header">
          <TopBar />
          {/* <Header/> */}
          <Navmenu />
        </header>
        <div className="container-fluid px-3">
          <ProductBreadcrumb
            home={
              <span>
                <span
                  className="mb-1"
                  style={{ fontSize: '1.6em', cursor: 'pointer' }}
                  onClick={() => navigate('/')}
                >
                  Home&nbsp;&nbsp;&nbsp;
                </span>
                <span
                  className="	fa fa-angle-right"
                  style={{ fontSize: '1.6em' }}
                ></span>
              </span>
            }
            slugObj1={id}
          />
        </div>
        <div class="product_detail_content">
          <div class="product_detail_side">
            {' '}
            {productimg ? (
              <div className="">
                <div className="fluid d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
                  <div className="fluid__image-container m-4">
                    <img
                      src={pimg}
                      alt="img"
                      className="img-fluid object-fit-cover shadow"
                      style={{ width: '100%', aspectRatio: '1/1' }}
                    />
                  </div>
                </div>
                <img
                  src={pimg}
                  alt="img"
                  className="img-fluid d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none object-fit-cover shadow"
                  style={{ width: '100%', aspectRatio: '1/1' }}
                />
                <div className="mt-3 ms-4 d-flex gap-3 my-5">
                  {productimg &&
                    productimg.map((item, index) => (
                      <>
                        <img
                          src={item}
                          key={index}
                          onClick={() => HandleClick(item)}
                          className="img-fluid object-fit-cover rounded-2 img-hover"
                          style={{ aspectRatio: '1/1', width: '80px' }}
                        />
                        {/* <p>{item}</p> */}
                      </>
                    ))}
                </div>
              </div>
            ) : (
              ' No image is available '
            )}
          </div>

          <div class="product_detail_main">
            <div className="  p-3 px-md-5">
              <strong
                className="fw-semibold text-capitalize"
                style={{ fontSize: '3em' }}
              >
                {product.name}
              </strong>
              <div>
                <p className="paraS">{renderHTML(product.short_decription)}</p>
                <p className="d-flex gap-2 align-items-center mb-4">
                  {/* <span className='fs-4 fw-semibold'>{defaultInfo.price}</span> */}
                  {/* {defaultInfo[0] ? ( */}

                  <span className="text-secondary">
                    {/* <del className="paraS"><i class="fa fa-inr" aria-hidden="true"></i>{product?.mrp}</del> */}

                    {defaultInfo[0] && defaultInfo[0]?.mrp ? (
                      <>
                        <del
                          className="fw-bold me-2  colorBlue"
                          style={{ fontSize: '2em' }}
                        >
                          ₹{defaultInfo[0].mrp}
                        </del>
                      </>
                    ) : (
                      <>
                        <del
                          className="fw-bold me-2  colorBlue"
                          style={{ fontSize: '2em' }}
                        >
                          ₹{product.mrp}
                        </del>
                      </>
                    )}
                  </span>

                  {defaultInfo[0] && defaultInfo[0]?.price ? (
                    <>
                      <span
                        className="fw-bold me-2  colorBlue"
                        style={{ fontSize: '2em' }}
                      >
                        ₹{defaultInfo[0].price}
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="fw-bold me-2  colorBlue"
                        style={{ fontSize: '2em' }}
                      >
                        ₹{product.price}
                      </span>
                    </>
                  )}
                </p>

                <div>
                  {/* {
                      productvarient.length !== 0 ?
                        <>
                          <h6 className='fw-semibold mb-3' style={{ fontSize: "13px" }}>SELECT SIZE</h6>

                          <div className='d-flex gap-3 mb-4 bg-dark w-50 rounded-2 px-3' style={{ fontSize: "14px" }}>

                            {
                              productvarient && productvarient.map((item, index) => (
                                <>
                                  <span className={`text-white px-2 py-1 rounded-1 size ${item === defaultInfo ? 'default-size' : ''
                                    }`} key={index} onClick={() => setGetDefault(item)}>{item.size}</span>
                                </>
                              ))
                            }
                          </div>

                        </> : ""
                    } */}

                  {product &&
                    product.Product_varients &&
                    product.Product_varients.length !== 0 ? (
                    <>
                      <h6
                        className="fw-semibold mb-3"
                        style={{ fontSize: '13px' }}
                      >
                        SELECT SIZE
                      </h6>

                      <div
                        className="d-flex gap-3 mb-3 w-100 rounded-2"
                        style={{ fontSize: '14px', flexWrap: 'wrap' }}
                      >
                        {product &&
                          product.Product_varients &&
                          product.Product_varients.length > 0 &&
                          product.Product_varients.map((item, index) => (
                            <>
                              <span
                                className={`sizeSpan p-2  ${item === defaultInfo[0] ? 'sizeSpanD' : ''
                                  }`}
                                onClick={() => setGetDefault(item)}
                              >
                                <span
                                  key={index}
                                  className={` psize  ${item === defaultInfo[0]
                                      ? 'default-size'
                                      : ''
                                    }`}
                                >
                                  {item.size}
                                </span>
                              </span>

                            </>
                          ))}
                      </div>

                    </>
                  ) : (
                    ''
                  )}




                </div>

                {/* <Accordion
                  defaultActiveKey=""
                  className="my-4 col-lg-9 col-12 w-100"
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span
                        style={{ fontSize: '14px' }}
                        className="fw-semibold"
                      >
                        Product Description
                      </span>
                    </Accordion.Header>
                    <Accordion.Body className="paraS">
                      {renderHTML(product.long_decription)}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span
                        style={{ fontSize: '14px' }}
                        className="fw-semibold"
                      >
                        Shipping info
                      </span>
                    </Accordion.Header>
                    <Accordion.Body className="paraS">
                      {renderHTML(product.shipping_info)}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span
                        style={{ fontSize: '14px' }}
                        className="fw-semibold"
                      >
                        Return Policy
                      </span>
                    </Accordion.Header>
                    <Accordion.Body className="paraS">
                      {renderHTML(product.refund_policy)}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion> */}
              </div>
            </div>
            <div
              className="   p-3 px-md-5"
              style={{
                clear: 'both',
              }}
            >
              <div className="row">
                <div className="col-12 col-md-6 my-3">
                  <label className="ps-2 py-2 fs-4">Baby Name</label>
                  <input
                    type="text"
                    placeholder="Enter Baby Name"
                    name="baby_name"
                    className="form-control border border-1 p-3 rounded-pill fs-4"
                    onChange={(e) => onInputChanged(e)}
                  />

                </div>
                <div className="col-12 col-md-6 my-3">
                  <label className="ps-2 py-2 fs-4">Baby Date Of Birth</label>
                  <input
                    type="date"
                    placeholder="Enter Baby Date Of Birth"
                    name="baby_dob"
                    className="form-control border border-1 p-3 rounded-pill fs-4"
                    onChange={(e) => onInputChanged(e)}
                  />

                </div>
                <div className="col-12 col-md-6 my-3">
                  <label className="ps-2 py-2 fs-4 ">Baby Time Of Birth(12:12)</label>

                  <input
                    type="time"
                    placeholder="Enter Baby Time Of Birth"
                    name="baby_tob"
                    className="form-control border border-1 p-3 rounded-pill fs-4"
                    onChange={(e) => onInputChanged(e)}
                  />

                </div>
                <div className="col-12 col-md-6 my-3">
                  <label className="ps-2 py-2 fs-4">Baby Weight</label>

                  <input
                    type="number"
                    placeholder="Enter Baby Weight"
                    name="baby_weight"
                    className="form-control border border-1 p-3 rounded-pill fs-4"
                    onChange={(e) => onInputChanged(e)}
                  />

                </div>
                <div className="col-12 col-md-6 my-3">
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label className="ps-2 py-2 fs-4">
                      {/* Baby photo */}
                     {`Upload ${product.number_of_baby_image} baby Images`}
                    </Form.Label>
                    <input
                      type="file"
                      name="baby_image"
                      // placeholder={`Upload ${product.Number_of_baby_image} baby Images:`}
                      className="form-control border border-1 p-3 rounded-pill fs-4"
                      onChange={(e) => setBabyImage(e.target.files)}
                    />
                  </Form.Group>
                </div>
                <div className="col-12 col-md-6 my-3">
                  <label className="ps-2 py-2 fs-4">Hospital Name</label>

                  <input
                    type="text"
                    placeholder="Enter Hospital Name or place where Baby Birth"
                    name="hospital_name"
                    className="form-control border border-1 p-3 rounded-pill fs-4"
                    onChange={(e) => onInputChanged(e)}
                  />

                </div>
                <div className="col-12 col-md-6 my-3">
                  <label className="ps-2 py-2 fs-4">
                    Choose Baby Boy Or Girl
                  </label>

                  <select
                    aria-label="Floating label select example"
                    name="baby_gender"
                    className="form-control border border-1 p-3 rounded-pill fs-4"
                    onChange={(e) => onInputChanged(e)}
                  >
                    <option>--Select Baby Gender--</option>
                    <option value="girl">girl</option>
                    <option value="boy">boy</option>
                  </select>

                </div>
                <div className="col-12 col-md-6 my-3">
                  <label className="ps-2 py-2 fs-4"> Baby Blood Group (Optional)</label>

                  <input
                    type="text"
                    placeholder="Enter The Blood Group Of The Baby"
                    name="blood_group"
                    className="form-control border border-1 p-3 rounded-pill fs-4"
                    onChange={(e) => onInputChanged(e)}
                  />

                </div>
                <div className="col-12 col-md-6 my-3">

                  <label className="ps-2 py-2 fs-4"> Baby Mother Name:</label>

                  <input
                    type="text"
                    placeholder="Enter Mother Name:"
                    name="mother_name"
                    className="form-control border border-1 p-3 rounded-pill fs-4"
                    onChange={(e) => onInputChanged(e)}
                  />

                </div>
                <div className="col-12 col-md-6 my-3">
                  <label className="ps-2 py-2 fs-4"> Baby Father Name</label>

                  <input
                    type="text"
                    placeholder="Enter Father Name:"
                    name="father_name"
                    className="form-control border border-1 p-3 rounded-pill fs-4"
                    onChange={(e) => onInputChanged(e)}
                  />

                </div>
                {
                  product.momImage?
                    <>
                      <div className={`col-12 col-md-6 my-3 `}>
                        {/* <p>{product.momImage}</p> */}
                        <label className="ps-2 py-2 fs-4"> Baby Mother Image</label>

                        <input
                          type="file"
                          placeholder="Upload Mother Image:"
                          name="mother_image"
                          className="form-control border border-1 p-3 rounded-pill fs-4"
                          onChange={(e) => setMotherImage(e.target.files)}
                        />

                      </div>
                    </> : ""
                }

                {
                  product.dadImage?
                    <>
                      <div className={`col-12 col-md-6 my-3 `}>
                        {/* <p>{product.dadImage}</p> */}

                        <label className="ps-2 py-2 fs-4"> Baby Father Image</label>

                        <input
                          type="file"
                          placeholder="Upload Father Image:"
                          name="father_image"
                          className="form-control border border-1 p-3 rounded-pill fs-4"
                          onChange={(e) => setFatherImage(e.target.files)}
                        />

                      </div>
                    </> : ""
                }
                {
                  product.parentImage?
                    <>
                      <div className="col-12 col-md-6 my-3">

                        <label className="ps-2 py-2 fs-4"> {`Upload ${product.number_of_parent_image} Parents Image`}</label>

                        <input
                          type="file"
                          // placeholder={`Upload ${product.number_of_parent_image} Parents Image:`}
                          name="parents_image"
                          className="form-control border border-1 p-3 rounded-pill fs-4"
                          onChange={(e) => setParentsImage(e.target.files)}
                        />

                      </div>
                    </>
                    : ""
                }



                <div className="row">
                  <div className="col-md-6">
                    {product.quantity > 0 ? (
                      loading ? (
                        <Spinner animation="border" />
                      ) : (
                        <button
                          type="button"
                          className="buttonBlue w-100 my-lg-4 my-4 m-2 fs-4 text-center px-5 py-3 rounded-2 fw-semibold"
                          onClick={() => {
                            handleComplete(product);

                          }}
                        >
                          Add to cart
                        </button>
                      )
                    ) : (
                      <button
                        type="button"
                        className=" buttonBlue w-100 my-lg-4 my-4 m-2 fs-4 text-center px-5 py-3 rounded-2 fw-semibold"
                        disabled
                      >
                        Out of stock
                      </button>
                    )}
                  </div>
                  <div className="col-md-6">
                    <Link
                      to={whatsappUrl}
                      style={{ textDecoration: 'none', color: '#000' }}
                    >
                      <button
                        type="button"
                        className="buttonPink w-100 my-lg-4 my-4 m-2 fs-4 text-center px-5 py-3 rounded-2 fw-semibold"
                      >
                        Share
                      </button>
                    </Link>
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>

        <footer class="product_detail_footer">
          <Footer />
        </footer>
      </div>

      {/* ////////// */}
    </>
  );
};
export default memo(ProductDetails);
