import React, { useEffect, useState } from 'react';
import './cart.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Layout from '../Components/Layout';
import TopBar from '../Components/TopBar';
import Navmenu from '../Components/Navmenu';
import Footer from '../Components/Footer';

const initialValues = {
  baby_name: '',
  baby_dob: '',
  baby_tob: '',
  baby_weight: '',
  hospital_name: '',
  baby_gender: '',
  blood_group: '',
  mother_name: '',
  father_name: '',
  // baby_image:''
};
function Cartinfo() {
  const [values, setValues] = useState(initialValues);
  const [baby_image, setBabyImage] = useState('');
  const [mother_image, setMotherImage] = useState('');
  const [father_image, setFatherImage] = useState('');
  const [parents_image, setParentsImage] = useState('');
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [frameinfo, setFrameInfo] = useState([]);
  const { state } = useLocation();

  const getTempData = () => {
    let tmpID = localStorage.getItem('tmpID');
    axios
      .post(
        `${BASE_URL}getfromeInfoByTempId`,
        {
          tempId: tmpID,
          frameInfoId: state,
        },
        {
          headers: {
            'Content-Type': 'Application/json',
            version: '1.0.0',
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          // setFrameInfo(response.data.data);
          setValues(...response.data.data);
          setBabyImage(response.data.data[0].baby_image);
          // console.log("babyimage",response.data.data[0].baby_image);
          setMotherImage(response.data.data[0].mother_image);
          setFatherImage(response.data.data[0].father_image);
          setParentsImage(response.data.data[0].parents_image);

          console.log(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const updateFrameInfo = () => {
    const fd = new FormData();
    // fd.append('tempId', tmpID);
    fd.append('baby_name', values.baby_name);
    fd.append('baby_dob', values.baby_dob);
    fd.append('baby_tob', values.baby_tob);
    fd.append('baby_weight', values.baby_weight);
    fd.append('hospital_name', values.hospital_name);
    fd.append('baby_gender', values.baby_gender);
    fd.append('blood_group', values.blood_group);
    fd.append('mother_name', values.mother_name);
    fd.append('father_name', values.father_name);
    fd.append('_id',values._id);
    if (baby_image) {
      fd.append('baby_image', baby_image[0]);
    }
    if (mother_image) {
      fd.append('mother_image', mother_image[0]);
    }
    if (father_image) {
      fd.append('father_image', father_image[0]);
    }
    if (parents_image) {
      fd.append('parents_image', parents_image[0]);
    }
    // fd.append('product_id', product._id);

    axios
      .post(`${BASE_URL}updatefromeInfoById`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
          version: '1.0.0',
        },
      })
      .then((res) => {
        if (res.data.success) {
          // console.log()
          getTempData();
          toast.success('updated  successfully');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTempData();
    console.log('state', state);
  }, []);

  const onInputChanged = (event) => {
    // fd.append()
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setValues((values) => ({
      ...values,
      [targetName]: targetValue,
    }));
  };
  return (
    <>
      {/* <Layout>
       */}

      <TopBar />
      {/* <Header/> */}
      <Navmenu />
      <section class="cart-info-ection">
        <img
          src="https://elzero.org/images/challenges/person-blue-shirt.png"
          alt="Person Pointing"
          class="cart-info-img"
        />
        <div class="info-box">
          <br />
          <div>
            <h1 class="text-white mt-5 text-center">Frame Info </h1>
            <div class="w-100  d-flex justify-content-center">
              <div className="row bg-white shadow-sm p-5 frameInfoDiv">
                <div className="col-md-6">
                  <label className="py-2 fs-4"> Baby Name</label>
                  <input
                    type="text"
                    name="baby_name"
                    class="form-control p-3 fs-4"
                    placeholder="Baby Name"
                    value={values.baby_name}
                    onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 fs-4"> Baby Date Of Birth</label>
                  <input
                    type="date"
                    name="baby_dob"
                    class="form-control p-3 fs-4"
                    placeholder="Enter Baby Date Of Birth"
                    value={moment(values?.baby_dob).format('YYYY-MM-DD')}
                    onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 fs-4"> Baby Time Of Birth</label>
                  <input
                    type="time"
                    name="baby_tob"
                    class="form-control p-3 fs-4"
                    placeholder="Enter Baby Time Of Birth"
                    value={values?.baby_tob}
                    onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <label className="py-2 fs-4"> Baby Weight</label>
                  <input
                    type="text"
                    name="baby_weight"
                    class="form-control p-3 fs-4"
                    placeholder="Enter Baby Weight"
                    value={values?.baby_weight}
                    onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <label className="py-2 fs-4"> Hospital Name</label>
                  <input
                    type="text"
                    className="form-control p-3 fs-4"
                    name="hospital_name"
                    placeholder="Enter Hospital Name"
                    value={values?.hospital_name}
                    onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <label className="py-2 fs-4">Choose Baby Boy Or Girl</label>
                  <select
                    aria-label="Floating label select example"
                    name="baby_gender"
                    className="form-control p-3 fs-4"
                    onChange={(e) => onInputChanged(e)}
                    value={values?.baby_gender}
                  >
                    <option>------</option>
                    <option value="girl">girl</option>
                    <option value="boy">boy</option>
                  </select>
                </div>

                <div className="col-md-6">
                  <label className="py-2 fs-4">Baby Blood Group</label>
                  <input
                    type="text"
                    name="blood_group"
                    className="form-control p-3 fs-4"
                    placeholder="Enter Hospital Name"
                    value={values?.blood_group}
                    onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 fs-4">Mother Name</label>
                  <input
                    type="text"
                    className="form-control p-3 fs-4"
                    placeholder="Enter Mother Name"
                    name="mother_name"
                    value={values?.mother_name}
                    onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 fs-4">Father Name</label>
                  <input
                    type="text"
                    className="form-control p-3 fs-4"
                    name="father_name"
                    placeholder="Enter Father Name"
                    value={values?.father_name}
                    onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 fs-4">Baby Image</label>
                  <input
                    type="file"
                    placeholder="Upload Baby Image:"
                    name="baby_image"
                    className="form-control p-3 fs-4"
                    onChange={(e) => setBabyImage(e.target.files)}
                  />
                  <br />
                  <img
                    src={baby_image}
                    height="auto"
                    style={{ width: '100px' }}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 fs-4">Mother Image</label>
                  <input
                    type="file"
                    placeholder="Upload Baby Image:"
                    name="mother_image"
                    className="form-control p-3 fs-4"
                    onChange={(e) => setMotherImage(e.target.files)}
                  />
                  <br />
                  <img
                    src={mother_image}
                    height="auto"
                    style={{ width: '100px' }}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 fs-4">Father Image</label>
                  <input
                    type="file"
                    placeholder="Upload Father Image:"
                    name="father_image"
                    className="form-control p-3 fs-4"
                    onChange={(e) => setFatherImage(e.target.files)}
                  />
                  <br />
                  <img
                    src={father_image}
                    height="auto"
                    style={{ width: '100px' }}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 fs-4">Parents Image</label>
                  <input
                    type="file"
                    placeholder="Upload Parents Image:"
                    name="parents_image"
                    className="form-control p-3 fs-4"
                    onChange={(e) => setParentsImage(e.target.files)}
                  />
                  <br />
                  <img
                    src={parents_image}
                    height="auto"
                    style={{ width: '100px' }}
                  />
                </div>

                <div className="col-md-12">
                  <button
                    className="btn buttonBlue p-3 w-100 m-3 fs-4"
                    onClick={() => updateFrameInfo()}
                  >
                    Update
                  </button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer/> */}
      {/* </Layout> */}
    </>
  );
}

export default Cartinfo;
