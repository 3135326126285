import React, { useEffect, useState } from 'react'
import Navmenu from '../Components/Navmenu';
import TopBar from '../Components/TopBar';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { Table } from 'react-bootstrap';

function OrderDetail() {
  const navigate = useNavigate();
   const [values, setValues] = useState([]);
   const [baby_image, setBabyImage] = useState('');
   const [mother_image, setMotherImage] = useState('');
   const [father_image, setFatherImage] = useState('');
  const [parents_image, setParentsImage] = useState('');
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { state } = useLocation();
  console.log("satte", state);
  let item = state.item;



   const getTempData = () => {
     let tmpID = localStorage.getItem('tmpID');
     axios
       .post(
         `${BASE_URL}getfromeInfoByTempId`,
         {
           tempId: tmpID,
           frameInfoId: item.cart[0].frameInfoId,
         },
         {
           headers: {
             'Content-Type': 'Application/json',
             version: '1.0.0',
           },
         }
       )
       .then((response) => {
         if (response.data.success) {
           // setFrameInfo(response.data.data);
           setValues(...response.data.data);
           setBabyImage(response.data.data[0].baby_image);
           // console.log("babyimage",response.data.data[0].baby_image);
           setMotherImage(response.data.data[0].mother_image);
           setFatherImage(response.data.data[0].father_image);
           setParentsImage(response.data.data[0].parents_image);

           console.log("frame info",response.data.data);
         }
       })
       .catch((err) => {
         console.log(err.message);
       });
  };
  
  useEffect(() => {
    getTempData();
  },[])
  return (
    <>
      <TopBar />
      {/* <Header/> */}
      <Navmenu />
      <section class="cart-info-ection">
        <img
          src="https://elzero.org/images/challenges/person-blue-shirt.png"
          alt="Person Pointing"
          class="cart-info-img"
        />
        <div class="info-box">
          <br />
          <div>
            <h1 class="text-white mt-5 text-center">Order Details </h1>
            <div class="w-100  d-flex justify-content-center">
              <div className="row bg-white shadow-sm p-5 frameInfoDiv">
                <div className="col-md-6">
                  <p className="" style={{ fontSize: '1.4em' }}>
                    your Name
                  </p>
                  <input
                    type="text"
                    name="Name"
                    class="form-control p-3 fs-4"
                    placeholder=" your Name"
                    value={item.name}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    OrderId{' '}
                  </p>
                  <input
                    type="text"
                    name="user_Order_ID"
                    class="form-control p-3 fs-4"
                    placeholder="OrderId"
                    value={item.user_Order_ID}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6 ">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Email{' '}
                  </p>
                  <input
                    type="text"
                    name="Email"
                    class="form-control p-3 fs-4"
                    placeholder="Email"
                    value={item.email}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6 ">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Mobile number{' '}
                  </p>
                  <input
                    type="text"
                    name="mobile_no"
                    class="form-control p-3 fs-4"
                    placeholder="Mobile number"
                    value={item.mobile_no}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6 ">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Address{' '}
                  </p>
                  <input
                    type="text"
                    name="address"
                    class="form-control p-3 fs-4"
                    placeholder="Address"
                    value={item.address}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                {/* <div className="col-md-6 ">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Address 2{' '}
                  </p>
                  <input
                    type="text"
                    name="baby_name"
                    class="form-control p-3 fs-4"
                    placeholder="Baby Name"
                    // value={values.baby_name}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div> */}

                <div className="col-md-6 ">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    State
                  </p>
                  <input
                    type="text"
                    name="state"
                    class="form-control p-3 fs-4"
                    placeholder="State"
                    value={item.state}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6 ">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    City
                  </p>
                  <input
                    type="text"
                    name="city"
                    class="form-control p-3 fs-4"
                    placeholder="City"
                    value={item.city}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6 ">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Pin Code{' '}
                  </p>
                  <input
                    type="text"
                    name="zip_code"
                    class="form-control p-3 fs-4"
                    placeholder="Pin Code"
                    value={item.zip_code}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6 ">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Coupon Code{' '}
                  </p>
                  <input
                    type="text"
                    name="coupon_code"
                    class="form-control p-3 fs-4"
                    placeholder="Coupon Code"
                    value={item?.coupon_code_details[0]?.coupon_code}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6 ">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Amount{' '}
                  </p>
                  <input
                    type="text"
                    name="amount"
                    class="form-control p-3 fs-4"
                    placeholder="Amount"
                    value={item.amount}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Discount Amount{' '}
                  </p>
                  <input
                    type="text"
                    name="discount_amount"
                    class="form-control p-3 fs-4"
                    placeholder="Discount Amount"
                    value={item?.discount_amount}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Total Amount{' '}
                  </p>
                  <input
                    type="text"
                    name="total_amount"
                    class="form-control p-3 fs-4"
                    placeholder=" Total Amount"
                    value={item.total_amount}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Payment Id{' '}
                  </p>
                  <input
                    type="text"
                    name="razorpay_payment_id"
                    class="form-control p-3 fs-4"
                    placeholder="Payment Id"
                    value={item.razorpay_payment_id}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Shipping Charges{' '}
                  </p>
                  <input
                    type="text"
                    name="shipping_charge"
                    class="form-control p-3 fs-4"
                    placeholder="Shipping Charges"
                    value={item.shipping_charge}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>
                <div className="col-md-6">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Payment Type{' '}
                  </p>
                  <input
                    type="text"
                    name="delivery_type"
                    class="form-control p-3 fs-4"
                    placeholder="Payment Type"
                    value={item.delivery_type}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="col-md-6">
                  <p className="mt-2 fw-4" style={{ fontSize: '1.4em' }}>
                    {' '}
                    Order Date{' '}
                  </p>
                  <input
                    type="date"
                    name="createdAt"
                    class="form-control p-3 fs-4"
                    placeholder="Order Date"
                    value={moment(item?.createdAt).format('YYYY-MM-DD')}
                    // onChange={(e) => onInputChanged(e)}
                  />
                </div>

                <div className="mt-5">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th className="fs-4">Product Image:</th>
                        <th className="fs-4">Product name:</th>

                        <th className="fs-4"> Price:</th>
                        {/* <th> Color:</th> */}
                        <th className="fs-4"> Size:</th>
                        <th className="fs-4"> Quantity:</th>
                        {/* <th> heading</th> */}
                        {/* <th> product description</th> */}
                        {/* <th>Status</th> */}
                        <th className="fs-4">Frame Info</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.cart.length > 0 &&
                        item.cart.map((item, index) => (
                          <tr key={index}>
                            {/* <p>{item}</p> */}
                            <td>
                              <img
                                src={`${item.Product_details[0].image[0]}`}
                                style={{ width: '40px', height: '50px' }}
                              />
                            </td>
                            <td className="fs-4">
                              {item.Product_details[0].name}
                            </td>
                            <td className="fs-4">
                              {item.price
                                ? item.price
                                : item.Product_details[0].price}
                            </td>
                            {/* <td>{item.color}</td> */}
                            <td className="fs-4">{item.size}</td>
                            <td className="fs-4">{item.quantity}</td>
                            <td className="fs-4">
                              <button
                                className="btn btn-primary fs-4"
                                onClick={() =>
                                  navigate('/frame-info', { state: { item } })
                                }
                              >
                                <i className="fa fa-eye"></i>
                              </button>
                            </td>

                            {/* <td>{renderHTML(item.Product_details[0].short_decription)}</td> */}

                            {/* <td>{item.description}</td> */}

                            {/* <td>{item.quantity}</td> */}
                            {/* <td>

                                                  <button onClick={()=>console.log("image",item.Product_details[0].image[0])}>click</button>
                                                  
                                                </td> */}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>

                {/* ---------------------------------- */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OrderDetail