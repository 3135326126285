import React, { memo, useEffect, useState } from "react";
import { Nav, ListGroup } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import IndianStates from "../../data/IndianStates.json";
import Profilenav from "../../Components/Profilenav";


const Layout = React.lazy(() => import('../../Components/Layout'));
const SideBar = React.lazy(() => import("./sideBar"));

// const initialState = {
//   firstname: "",
//   lastname: "",
//   email: "",
//   mobile_no: "",
//   address1: "",
//   address2: "",
//   state: "",
//   city: "",
//   zip_code: "",
// };

const ChangePassword = () => {
  const navigate = useNavigate();
  // const [email, setEmail] = useState("");
  const [old_password, setOld_password] = useState("");
  const [new_password , setNew_password]=useState("");
  const [confirm_password , setConfirm_password]=useState("");

  // const [values, setValues] = useState(initialState);
  // const [states, setStates] = useState([]);
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user == null) {
      navigate("/sign-in");
    }
    // setStates(IndianStates);
  }, []);

  // const handleChange = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };
  const submit = () => {
    let token = localStorage.getItem("token");
    if(new_password!=confirm_password)
    {
       toast.error("new_password is not matching to the confirm_password");
    }
    const obj={
      old_pass:old_password,
      new_pass:new_password
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}auth/change-password`,
      obj,

        {
          headers: {
            // version: "1.0.0",
            "x-access-token": ` ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.data.success === false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          localStorage.setItem("user", response.data.data);
          navigate("/");
        }
        // console.log("response =>", response.data.success);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
      });
  };
  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="container">
            <Profilenav />
            <div className="">
              <div className="row  d-flex justify-content-center">
                <div
                  className="col-md-6"
                  style={{
                    margin: '30px',
                    backgroundColor: '#f2f2f2',
                    boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  }}
                >
                  <h3 className="text-center p-4">Update password</h3>
                  <div className="p-4">
                    <div className="row">
                      <div className="col">
                        <div className="mb-3" controlId="formBasicEmail">
                          <label className="paraS my-4 fs-4">
                            Old password
                          </label>
                          <input
                            type="text"
                            placeholder="Enter old password"
                            onChange={(e) => setOld_password(e.target.value)}
                            name="old_password"
                            value={old_password}
                            className="checkoInput "
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3" controlId="formBasicEmail">
                          <label className="paraS my-4 fs-4">
                            New password
                          </label>
                          <input
                            type="text"
                            placeholder="Enter new password"
                            onChange={(e) => setNew_password(e.target.value)}
                            name="new_password"
                            value={new_password}
                            className="checkoInput"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3" controlId="formBasicEmail">
                      <label className="paraS my-4 fs-4">
                        Confirm password
                      </label>
                      <input
                        type="text"
                        placeholder="confirm password"
                        name="confirm_password"
                        onChange={(e) => setConfirm_password(e.target.value)}
                        value={confirm_password}
                        className="checkoInput "
                      />
                    </div>

                    <div className="mb-3" controlId="formBasicEmail">
                      <button
                        className="buttonBlue "
                        style={{
                          width: '100%',
                          padding: '5px',
                          backgroundColor: 'rgba(0,0,0,0.87)',
                          color: '#fff',
                        }}
                        onClick={() => submit()}
                      >
                        <h5>Update profile</h5>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default memo(ChangePassword);
