import React, {
  Suspense,
  createContext,
  memo,
  useEffect,
  useState,
} from "react";
import { ThreeCircles } from "react-loader-spinner";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import "react-perfect-scrollbar/dist/css/styles.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import "react-multi-carousel/lib/styles.css";
import ChangePassword from "./pages/User/ChangePassword";
// import Otp from "./pages/auth/Otp";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/Terms&Conditions";
import ProductBreadcrumb from "./Components/productBreadcrumb";
import ReturnPolicy from "./pages/ReturnPolicy";
import AboutUs from "./pages/AboutUs.js";
import Categories from "./pages/Categories";
import BulkOrderInquiry from "./pages/BulkOrderInquiry";
// import ProductSlider from "./pages/productslider";
// import ViewAll from "./pages/HomeCategoryProduct";
// import HomeCategoryProduct from "./pages/Product/HomeCategoryProduct.js";
// import MobileViewFooter from "./Components/MobileViewFooter";
// import SearchBar from "./Components/Searchbar";
// import WelcomePopUp from "./Components/WindowPopUp";
import ViewOrder from "./pages/User/ViewOrder";
import Receipt from "./pages/Receipt";

// import NavScrollExample from "./Components/NavScrollExample";
import Navmenu from "./Components/Navmenu.js";
import Testimonial from "./Components/Testimonial.js";
import Newarrivals from "./Components/Newarrivals.js";
import "./App.css";
// import RelatedProduct from "./pages/Product/RelatedProduct.js";
// import Range from "./Components/Range.js";
import Review from "./pages/Review.js";
// import SearchProduct from "./pages/Product/SearchProduct.js";
// import Wizard from "./pages/Wizard.js";
import BabyrameInfo from "./pages/BabyrameInfo.js";
// import Cart_copy from "./pages/Cart.js/index.js";
import ProductDetails_sticky from "./pages/Product/ProductDetails.js";
import ProductCart from "./pages/Cart.js";
import Cartinfo from "./pages/Cartinfo.js";
import OrderDetail from "./pages/OrderDetail.js";
import FrameInfo from "./pages/FrameInfo.js";
// import ProductCart from "./pages/Cart.js";
//page
const Home = React.lazy(() => import("./pages/Home"));
const SignUp = React.lazy(() => import("./pages/auth/SignUp"));
const SignIn = React.lazy(() => import("./pages/auth/SignIn"));
const MyProfile = React.lazy(() => import("./pages/auth/MyProfile"));
const ProductListing = React.lazy(() =>
  import("./pages/Product/ProductListing")
);
const ProductCateAndSubCatListing = React.lazy(() =>
  import("./pages/Product/ProductCateAndSubCatListing")
);
const ProductDetails = React.lazy(() =>
  import("./pages/Product/ProductDetails")
);
const Checkout = React.lazy(() => import("./pages/Checkout"));
const Cart = React.lazy(() => import("./pages/Cart.js"));
const Order = React.lazy(() => import("./pages/User/Order"));

const CartContext = createContext();
const WebInfoContext = createContext();

// import Home from "./pages/Home";
// import SignUp from "./pages/auth/SignUp";
// import SignIn from "./pages/auth/SignIn";
// import MyProfile from "./pages/auth/MyProfile";
// import ProductListing from "./pages/Product/ProductListing";
// import ProductCateAndSubCatListing from "./pages/Product/ProductCateAndSubCatListing";
// import ProductDetails from "./pages/Product/ProductDetails";
// import Checkout from "./pages/Checkout";
// import Cart from "./pages/Cart";
// const cartItm=createContext();
const App = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // const [  cartItem   ,setCartItem]=useState([]);
  const [cartlen, setCartlen] = useState();
  // const ref = useRef();
  // const [cartiteminfo, setCartIteminfo] = useState([]);
  // const [imageHeight, setImageHeight] = useState(0);
  useEffect(() => {
    // To store data

    let tmpID = localStorage.getItem("tmpID");
    if (tmpID == null) {
      let r = (Math.random() + 1).toString(36).substring(7);
      localStorage.setItem("tmpID", r + Date.now());
    }

    // console.log("tmpID ==>", tmpID);
  }, []);

  const initialState = {
    title: "",
    keyword: "",
    description: "",
    image: "",
    announcement: "",
  };
  const [metaTag, setMetaTag] = useState(initialState);
  // const [webInfo , setWebInfo]=useState();

  const getWebsiteInfo = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getWebsiteInfo`, {
        headers: {
          version: "1.0.0",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.data.success === false) {
        } else {
          // console.log(response.data.data);
          setMetaTag({
            description: response.data.data.description,
            keyword: response.data.data.keyword,
            favicon_icon: response.data.data.favicon_icon,
            title: response.data.data.title,
            logo: response.data.data.logo,
            announcement: response.data.data.announcement
          });
          // setWebInfo(response.data.data);
          // console.log("website info t the app.js",response.data.data);
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const loadCartList = () => {
    let tmpID = localStorage.getItem("tmpID");

    const obj = {
      tmpUserID: tmpID,
    };
    axios
      .post(`${BASE_URL}getAllCart`, obj, {
        headers: {
          version: "1.0.0",
          "Content-type": "Application/json",
        },
      })
      .then(function (response) {
        setCartlen(response.data.data.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getWebsiteInfo();
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/sign-up',
      element: <SignUp />,
    },
    {
      path: '/sign-in',
      element: <SignIn />,
    },
    {
      path: '/my-profile',
      element: <MyProfile />,
    },
    {
      path: '/order',
      element: <Order />,
    },

    {
      path: 'change-password',
      element: <ChangePassword />,
    },
    {
      path: '/category/:slug',
      element: <ProductListing />,
    },
    {
      path: '/category/:cat_slug/:sub_cat_slug',
      element: <ProductCateAndSubCatListing />,
    },
    {
      path: '/product-detail/:id',
      element: <ProductDetails />,
    },
    {
      path: '/checkout',
      element: <Checkout />,
    },
    {
      path: '/cart',
      element: <ProductCart />,
    },
    {
      path: '/PrivacyPolicy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/TermsAndCondition',
      element: <TermsAndCondition />,
    },
    {
      path: 'ProductBreadcrumb',
      element: <ProductBreadcrumb />,
    },
    {
      path: '/ReturnPolicy',
      element: <ReturnPolicy />,
    },
    {
      path: '/AboutUs',
      element: <AboutUs />,
    },
    {
      path: '/Categories',
      element: <Categories />,
    },
    {
      path: '/BulkOrderInquiry',
      element: <BulkOrderInquiry />,
    },
  
 
    {
      path: '/ViewOrder',
      element: <ViewOrder />,
    },
    {
      path: '/Receipt',
      element: <Receipt />,
    },
    // {
    //   path: '/test',
    //   element: <NavScrollExample />,
    // },
    {
      path: '/Navmenu',
      element: <Navmenu />,
    },
    {
      path: '/Testimonial',
      element: <Testimonial />,
    },
    {
      path: '/new-arriavals',
      element: <Newarrivals />,
    },
  

    {
      path: '/review',
      element: <Review />,
    },
   

    {
      path: '/cart-info',
      element: <Cartinfo />,
    },
    {
      path: '/order-detail',
      element: <OrderDetail />,
    },
    {
      path: '/order-detail',
      element: <OrderDetail />,
    },

    {
      path: '/frame-info',
      element: <FrameInfo/>,
    },
    // {
    //   path: '/product_details_sticky/:id',
    //   element: <ProductDetails_sticky />,
    // },
  ]);


  // const loadCartList = () => {
  //   let tmpID = localStorage.getItem("tmpID");

  //   const  obj = {
  //     tmpUserID: tmpID,
  //   };
  //   axios
  //     .post(`${BASE_URL}getAllCart`,obj ,
  //     {
  //       headers:{
  //         "version":"1.0.0",
  //         "Content-type":"Application/json"
  //       }
  //     })
  //     .then(function (response) {
  //       setCartIteminfo(response.data.data);
  //       // setCartlen(response.data.data.length);
  //       console.log("cartitem",response.data.data.length);
  //       // console.log("cart-cart-len",cartlen);
  //       // console.log("cart-length",response.data.data.length);
  //       // console.log("response image is",response.data.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  //   useEffect(()=>{
  // loadCartList();
  //   },[])

  return (
    <>
      <CartContext.Provider value={{ cartlen, loadCartList }}>
        <WebInfoContext.Provider value={{ metaTag, getWebsiteInfo }}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{metaTag.title}</title>
            <meta name="description" content={metaTag.description} />
            <meta name="keyword" content={metaTag.keyword} />
            <link rel="icon" href={metaTag.image} />
          </Helmet>

          <Suspense
            fallback={
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
              >
                <ThreeCircles
                  height="80"
                  width="80"
                  radius="9"
                  color="black"
                  ariaLabel="three-dots-loading"
                  wrapperStyle
                  wrapperClass
                />
              </div>
            }
          >
            <ToastContainer />
            <RouterProvider router={router} />
          </Suspense>
        </WebInfoContext.Provider>
      </CartContext.Provider>
    </>
  );
};

export default memo(App);
export { CartContext };
export { WebInfoContext };
