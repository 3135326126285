import React, { memo, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
import { ValidEmail, ValidMobileNumber } from "../function/Validation";

// import { createRoot } from "react-dom/client";
// import TopBar from "../Components/TopBar";
// import Header from "../Components/Header";
// import HomeCategory from "../Components/HomeCategory";
// import MainSlider from "../Components/MainSlider";
// import ProductCard from "../Components/ProductCard";
// import WordShipping from "../Components/WordShipping";
// import Footer from "../Components/Footer";
// import Occasion from "../Components/Occasion";
import placeholderImage from "../images/placeholder.webp";
import axios from "axios";

import { toast } from "react-toastify";
// import Testimonial from "../Components/Testimonial";
import Newarrivals from "../Components/Newarrivals";
import ProductCard1 from "../Components/ProductCard1";

import BestDeals from "../Components/BestDeals";
// import Occasion from '../Components/Occasion';
const Testimonial = React.lazy(() => import("../Components/Testimonial"));

const Layout = React.lazy(() => import("../Components/Layout"));
const ProductCard = React.lazy(() => import("../Components/ProductCard"));
// const WordShipping = React.lazy(() => import("../Components/WordShipping"));
const MainSlider = React.lazy(() => import("../Components/MainSlider"));

const Home = () => {
  // var utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');
  // document.write(utc);
  // console.log("todays date",utc);
  // let tmpID = localStorage.getItem("token");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_no, setMobile_No] = useState("");

  const handleClose = () => setShow(false);
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const validate = () => {
    if (name.length < 3 || name.length > 20) {
      toast.error("Name Should be of 3 to 20 Characters");
    }
    if (!ValidEmail(email)) {
      toast.error("Please Enter Valid Email");
    }
    if (!ValidMobileNumber(mobile_no)) {
      toast.error("Please Enter Correct Mobile numer");
    }
  };

  useEffect(() => {
    let currentDate = localStorage.getItem("currentDate");
    if (currentDate == null) {
      localStorage.setItem("currentDate", moment().format("DD-MM-YYYY"));
    } else {
      if (currentDate == moment().format("DD-MM-YYYY")) {
        console.log("date is same");
        setShow(false);
      } else {
        console.log("date not same");
        setShow(true);
      }
    }
  }, []);

  const href = window.location.href;

  const [homeCategory, setHomeCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [testimonialImg, setTestimonialImg] = useState([]);

  useEffect(() => {
    Category();
  }, []);

  let Category = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllCategory`)
      .then(function (response) {
        // handle success
        // if (mounted) {
        if (response.data.success) {
          setCategories(response.data.data);
        }
        // }

        // bindCategory();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const initialState = {
    sideBanner1: "",
    sideBanner2: "",
    sideBannerUrl1: "",
    sideBannerUrl2: "",
    footerBanner: "",
    footerBannerUrl: "",
  };
  const [metaTag, setMetaTag] = useState(initialState);

  useEffect(() => {
    getAllBanner();
  }, []);

  const getAllBanner = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllBanner`, {
        headers: {
          version: "1.0.0",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.data.success === false) {
        } else {
          console.log(response.data.data);
          setMetaTag(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Layout>
      <MainSlider />
      <div class="nine my-4">
        <h1>
          Categories
          <span className="">Empower your style</span>
        </h1>
      </div>

      <PerfectScrollbar className="">
        <div className="container-fluid d-flex gap-3">
          {categories.length > 0 &&
            categories.map((item, index) => (
              <>
                <div className="col-3" key={index} style={{ width: "25%" }}>
                  <Link
                    to={`/category/${item.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <img src={item.icon} className="w-100" loading="lazy" />
                  </Link>
                </div>
              </>
            ))}
        </div>
      </PerfectScrollbar>

      <Testimonial />

      <div style={{ backgroundColor: "#efefef" }}>
        {/* <Testimonial/> */}
        <div>
          <img
            src="https://crunkthread.com/wp-content/uploads/2023/03/FREE.jpg"
            className="img-fluid w-100"
            loading="lazy"
          />
        </div>
      </div>
    </Layout>
  );
};
export default Home;
