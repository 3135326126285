import React, { memo, useEffect, useState } from "react";
import Navmenu from "./Navmenu";
// import Header from "./Header";
// import TopBar from "./TopBar";
// import Footer from "./Footer";
const Header = React.lazy(() => import('./Header'));
const TopBar = React.lazy(() => import('./TopBar'));
const Footer = React.lazy(() => import('./Footer'));

const Layout = ({ children }) => {

  return (
    <>
      <TopBar />
      {/* <Header/> */}
      <Navmenu/>
      {children}
      <Footer />
    </>
  );
};

export default memo(Layout);
