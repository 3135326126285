import axios from "axios";
import React, { memo, useContext, useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
// import SearchBar from "./Searchbar";


import { CartContext, WebInfoContext } from '../App';

const TopBar = () => {
  const state=useContext(CartContext);
  const state2 = useContext(WebInfoContext);
  const [user, setUser] = useState();


  useEffect(() => {
    console.log('web topbar ...state2', state2.metaTag);
 
    let user = localStorage.getItem("user");
    if (user != null) {
      setUser(user);
    }
  }, []);

  useEffect(()=>{
    state?.loadCartList();
  },[state])

  return (
    <>
      <div className=" text-center text-light p-1 homeTopBar">
        <p className="mt-2 subheadingS">{state2.metaTag.announcement}</p>
      </div>
    </>
  );
};

export default memo(TopBar);
