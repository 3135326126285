import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
// import "./mobilemenu.css";
import './mobilemenu.css';
import { WebInfoContext } from "../App";


import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from 'axios';
import { Link } from 'react-router-dom';
export default function Mobilemenu(props) {

  const websiteInfo = useContext(WebInfoContext);

  //   const [showmenu, setShowmenu] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const BASE_URL=process.env.REACT_APP_BASE_URL;


  const getAllcategories = () => {
    axios
      .get(`${BASE_URL}getAllCategory`,
        {
          headers: {
            "Content-Type": "application/json",
            "version": "1.0.0"
          }
        }).then((response) => {
          if (response.data.success == false) {
            // console.log()
          } else {
            setAllCategories(response.data.data);
            console.log("categories", response.data.data);
          }
        })
  }

  useEffect(() => {
    getAllcategories();
    websiteInfo?.getWebsiteInfo();
    console.log("websiteInfo",websiteInfo.metaTag);
  }, [])

  return (
    <>
      <Offcanvas show={props.show} onHide={props.onHide} style={{width:"300px"}}>
        <Offcanvas.Header closeButton style={{ backgroundColor: "#fff" }} >
          <Offcanvas.Title ><img src={websiteInfo.metaTag?.logo} alt='' width={250} height={60} /></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ backgroundColor: "#fff" }} >
          <ul className='mobilemenuul p-0 '>
            {
              allCategories.length>0&& allCategories.map((item , index)=>(
                <>
                 <li className='p-3' key={index} style={{ borderBottom:"1px solid rgba(0,0,0,0.1)"}}>
              <p id='mobileNavLinks'>   <Link to={`/category/${item?.slug}`} className="desktop-item dottedText"  >{item?.name}</Link>
              {
                item.subCategory.length>0? <span className="material-symbols-outlined text-end" style={{float:"right"}}>
                expand_more
              </span>:""

              }
              </p>
             
              
             
              <ul className='submobilemenu'>
                {
                  item.subCategory.length>0&&item.subCategory.map((item1, index)=>(
                    <>
                       <li key={index}>
                       <Link to={`/category/${item?.slug}/${item1?.slug}`} id="subCategoryLink">{item1.name}</Link>
                  {/* <a id='submenuLinks' href="" >{item1.name}</a> */}
                 
                </li>
                    </>
                  ))
                }
              
              </ul>
            </li>

                </>
              ))
            }
            {/* <li className=''>
              <p id='mobileNavLinks'><a href="" >facial care</a> <span class="material-symbols-outlined text-end" style={{float:"right"}}>
                expand_more
              </span></p>
             
              
             
              <ul className='submobilemenu'>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                  <ul className='childmobilemenu'>
                    <li>about us </li>
                    <li>contactus</li>
                    <li>aptitude</li>
                  </ul>
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
              </ul>
            </li> */}


            {/* <li className=''>
              <p id='mobileNavLinks'><a href="" >facial care</a> <span class="material-symbols-outlined text-end" style={{float:"right"}}>
                expand_more
              </span></p>
             
              
             
              <ul className='submobilemenu'>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
                <li>
                  <a id='submenuLinks' href="" >Home</a>
                 
                </li>
              </ul>
            </li> */}




         


          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
